<template>
    <a-modal class="error-correction-dialog" :width="1000" centered :visible="true" :keyboard="false" :maskClosable="false" :closable="false">
        <template slot="title">
            <div class="title">
                <p>纠错</p>
                <div class="icon-close" @click="onCancel"><a-icon type="close-circle" theme="filled" /></div>
            </div>
        </template>
        <div class="form">
            <a-form-model ref="ruleForm" :model="form" :rules="rules" :hideRequiredMark="false">
                <div class="form-table">
                    <a-row class="form-table-row disabled">
                        <a-col>
                            <p class="form-item-label">资源名称</p>
                            <p class="form-item-value">
                                {{ data.resName }}
                            </p>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row disabled">
                        <a-col>
                            <p class="form-item-label">英文表名</p>
                            <p class="form-item-value">
                                {{ data.dataBaseName || '--' }}
                            </p>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                纠错数据项
                            </p>
                            <div class="form-item-checkbox">
                                <a-form-model-item prop="errorItemList">
                                    <a-checkbox-group
                                        v-model="form.errorItemList"
                                        name="errorItemList"
                                        :options="dataItems"
                                    />
                                </a-form-model-item>
                            </div>
                        </a-col>
                    </a-row>
                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                描述
                            </p>
                            <a-form-model-item prop="content">
                                <a-textarea v-model.trim="form.content" placeholder="请输入描述" :maxLength="2000" :rows="4"></a-textarea>
                            </a-form-model-item>
                        </a-col>
                    </a-row>

                    <a-row class="form-table-row">
                        <a-col>
                            <p class="form-item-label">
                                <span class="require">*</span>
                                验证码
                            </p>
                            <a-form-model-item prop="verifyCode">
                                <a-input v-model.trim="form.verifyCode" placeholder="请输入验证码" :maxLength="5" allow-clear @change="onChangeVerfyCode"></a-input>
                            </a-form-model-item>
                            <div @click.stop="refreshVerifyCode" class="verify-code-wrap"><verify-code :code="verifyCode"></verify-code></div>
                        </a-col>
                    </a-row>
                </div>
            </a-form-model>
        </div>
        <template slot="footer">
            <a-button class="btn-submit" key="submit" type="primary" :loading="confirmLoading" @click="onSubmit">提交</a-button>
        </template>
    </a-modal>
</template>

<script>
import VerifyCode from '@/components/VerifyCode.vue'
import { addErrorCorrection } from '../api/index'
export default {
    components: { VerifyCode },
    props: {
        data:{
            type:Object,
            default:()=>({})
        }
    },
    data() {
        let validateVerifyCode = (rule, value, callback) => {
            if (value === '') {
                callback(new Error('验证码不能为空'))
                return
            }
            if (this.verifyCode.toLowerCase() !== this.form.verifyCode.toLowerCase()) {
                callback(new Error('验证码错误，请重新输入'))
                return
            }
            callback()
        }
        let validateErrorItemList = (rule, value, callback) => {
            console.log('value=>',value);
            if (!value || !value.length) {
                callback(new Error('纠错数据项不能为空'))
                return
            }
            callback()
        }
        return {
            confirmLoading: false,
            form: {
                errorItemList:[],
                content: '',
                verifyCode: '',
            },
            rules: {
                content: [
                    {
                        required: true,
                        message: '描述不能为空',
                        trigger: ['change']
                    }
                ],
                errorItemList:[{ validator: validateErrorItemList, trigger: ['blur'] }],
                verifyCode: [{ validator: validateVerifyCode, trigger: ['blur'] }]
            },
            verifyCode: '',
            dataItems:[],
        }
    },
    mounted() {
        this.init()
        this.generateCode()
    },
    methods: {
        init(){
            let { dataItems } = this.data
            this.dataItems = dataItems
        },
        // 点击“提交”
        onSubmit() {
            console.log('errorItemList=>',this.form.errorItemList);
            this.$refs['ruleForm'].validate(valid => {
                if (valid) {
                    this.addErrorCorrection()
                }
            })
        },
        addErrorCorrection() {
            let params = {
                content: this.form.content,
                errorItemList:this.form.errorItemList,
                resId:this.data.resId,
                type: this.data.type,
            }
            this.confirmLoading = true
            addErrorCorrection(params)
                .then(res => {
                    this.$message.success('提交成功')
                    this.$emit('submit')
                })
                .finally(() => {
                    this.confirmLoading = false
                })
        },
        // 点击“取消”
        onCancel() {
            this.$emit('cancel')
        },
        // 验证码处理
        randomNum(min, max) {
            return Math.floor(Math.random() * (max - min) + min)
        },
        // 生成验证码
        generateCode(l = 5) {
            this.verifyCode = ''
            let str = '1234567890abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ'
            for (let i = 0; i < l; i++) {
                this.verifyCode += str[this.randomNum(0, str.length)]
            }
        },
        refreshVerifyCode() {
            this.form.verifyCode = ''
            this.generateCode()
        },
        onChangeVerfyCode() {
            this.$nextTick(() => {
                this.$refs['ruleForm'].clearValidate('verifyCode')
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.error-correction-dialog {
    /deep/ .ant-modal-header {
        border-bottom: none;
        padding: 0 40px;
        .ant-modal-title {
            border-bottom: 2px solid #ebeced;
            padding: 28px 0 28px;
            .title {
                display: flex;
                align-items: center;
                p {
                    font-size: 30px;
                    font-weight: bold;
                    color: #000025;
                }
                .icon-close {
                    margin-left: auto;
                    cursor: pointer;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    i {
                        color: #dbdbdb;
                        font-size: 20px;
                    }
                }
            }
        }
    }
    /deep/ .ant-modal-body {
        padding: 30px 40px;
        .form {
            padding: 0 40px;
            .form-table {
                border: 1px solid #d5d6e3;
                .form-table-row {
                    border-bottom: 1px solid #d5d6e3;
                    &.disabled{
                        background:#f0f2f4;
                    }
                    &:last-of-type {
                        border-bottom: 1px solid transparent;
                    }
                    .ant-col {
                        display: flex;
                        border-right: 1px solid #d5d6e3;
                        // min-height: 50px;
                        padding: 0 15px;
                        &:last-of-type {
                            border-right: 1px solid transparent;
                        }
                        .form-item-label {
                            height: 50px;
                            line-height: 50px;
                            width: 102px;
                            padding-left: 8px;
                            position: relative;
                            color: #000025;
                            flex-shrink: 0;
                            .require {
                                position: absolute;
                                left: 0;
                                top: 50%;
                                transform: translateY(-50%);
                                color: #ff0000;
                            }
                        }
                        .form-item-value{
                            display: flex;
                            align-items: center;
                        }
                        .form-item-checkbox{
                            .ant-form-item-control{
                                padding-bottom: 13px;
                            }
                        }
                        .ant-checkbox-group{
                            .ant-checkbox-group-item{
                                margin-top: 16px;
                            }
                        }
                        .ant-form-item {
                            flex: 1;
                            margin-bottom: 0;
                            .ant-form-item-control-wrapper {
                                padding: 0;
                                .ant-form-item-control {
                                    width: 100%;
                                    line-height: 1;
                                    .ant-input {
                                        width: 100%;
                                        border: 1px solid transparent;
                                        outline: 0;
                                        /* outline: none; */
                                        box-shadow: none;
                                        height: 50px;
                                        padding: 0;
                                    }
                                    textarea {
                                        padding-top: 14px !important;
                                        height: auto !important;
                                        margin: 0;
                                    }
                                    .ant-radio-group{
                                        height: 50px;
                                        line-height: 50px;
                                    }
                                }
                                .ant-select-selection {
                                    width: 100%;
                                    border: 1px solid transparent;
                                    outline: 0;
                                    /* outline: none; */
                                    box-shadow: none;
                                    height: 50px;
                                    padding: 0;
                                    .ant-select-selection__rendered,
                                    .ant-select-selection-selected-value {
                                        margin-left: 0;
                                        line-height: 48px;
                                    }
                                }
                            }
                            .ant-form-explain {
                                position: absolute;
                                bottom: 0;
                            }
                        }
                        .verify-code-wrap {
                            display: flex;
                            align-items: center;
                            flex-shrink: 0;
                        }
                    }
                }
            }
        }
    }
    /deep/ .ant-modal-footer {
        border-top: none;
        text-align: center;
        padding: 10px 0 40px;
        .btn-submit {
            height: 35px;
            line-height: 35px;
            width: 115px;
            text-align: center;
        }
    }
}
</style>