<template>
    <div class="NoData">
        <div class="nodata">
            <img :src="require('@/assets/index/indexnodata.png')" class="indexnodata" alt="" />
            <span class="tip">暂无数据</span>
        </div>
    </div>
</template>

<script>
export default {
    name: 'NoData',
    data() {
        return {}
    },
    methods: {},
    mounted() {}
}
</script>

<style scoped lang="scss">
.NoData {
    .nodata {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        justify-content: center;
        align-items: center;
        height: 620px;
        .indexnodata {
            width: 520px;
            display: block;
        }
        .tip {
            width: 100%;
            text-align: center;
            font-size: $fs12;
            color: #1d242e;
        }
    }
}
</style>
